<template>
  <div>
    <div v-if="!errorReportsSuccess || !zitiusSuccess">
      <v-alert
        v-if="!errorReportsSuccess"
        border="top"
        prominent
        type="error"
        class="mt-2 text-left"
      >
        OpenNetwork status system is down - please check later.
      </v-alert>

      <v-alert
        v-if="!zitiusSuccess"
        border="top"
        prominent
        type="error"
        class="mt-2 text-left"
      >
        Zitius status system is down - please check later.
      </v-alert>
    </div>

    <v-timeline
      v-if="reports.length > 0"
      align-top
      dense
    >
      <v-timeline-item
        v-for="(report, index) in reports"
        :key="index"
        :color="report.color"
        :icon="report.icon"
        fill-dot
      >
        <v-row>
          <v-col cols="12" class="pt-2 pb-0">
            <strong>{{ util.formatDateTime(report.downTime, 'MMM DD, HH:mm') }}</strong>
          </v-col>
          <template v-if="report.type === 'normal'">
            <v-col cols="12" class="pt-0">
              <div class="text-subtitle-1 font-weight-bold">
                {{ report.operatorName }}
                <span class="pl-2" :class="`${report.color}--text`" v-if="report.statusText">({{ report.statusText }})</span>
              </div>
              <div class="text-subtitle-2 font-weight-bold" v-if="report.expectedToWork">
                Beräknad klartid: {{ report.expectedToWork.includes('announced') ? report.expectedToWork : util.formatDateTime(report.expectedToWork, 'MMM DD, HH:mm') }}
              </div>
              <div class="text-subtitle-2 font-weight-bold">
                {{ report.title || 'Unknown' }}
              </div>
              <div v-html="util.convertTextToHtml(report.description)" />
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" class="pt-0">
              <div class="text-subtitle-1 font-weight-bold">
                {{ report.operatorName }}
                <span class="pl-2" v-if="report.regions">({{ report.regions }})</span>
              </div>
              <div class="text-subtitle-2 font-weight-bold" v-if="report.estimatedClearTime">
                Beräknad klartid: {{ util.formatDateTime(report.estimatedClearTime, 'MMM DD, HH:mm') }}
              </div>
              <div class="text-subtitle-2 font-weight-bold">
                Driftstörning ({{ report.serviceLogType }})
              </div>
              <div class="mb-2">
                Vi har förnärvarande en driftstörning.
              </div>
              <div class="mb-2" v-for="(item, index) in report.messages" :key="index">
                <div class="font-weight-bold">{{ util.formatDateTime(item.createdTime, 'MMM DD, HH:mm') }}</div>
                <div>{{item.message}}</div>
              </div>
              <div class="font-weight-bold">
                postnummer/adresser:
              </div>
              <div class="mb-2" v-for="(item, index) in report.addresses" :key="index + report.messages.length + 1">
                {{ item.streetName }} {{ item.streetNumber }}, {{ util.formatPostalCode(item.postalCode) }} {{ item.city }}
              </div>
            </v-col>
          </template>
        </v-row>
      </v-timeline-item>
    </v-timeline>
    <div
      v-else-if="errorReportsSuccess && zitiusSuccess"
      class="text-h6 text-center mt-4 pink--text"
    >Inga kända fel</div>
  </div>
</template>

<script>
  import util from '@/utils'

  export default {
    props: {
      reports: {
        type: Array,
        default: () => ([])
      },
      errorReportsSuccess: {
        type: Boolean,
        default: false,
      },
      zitiusSuccess: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      util: util,
    }),
  }
</script>
