<template>
  <div
    v-if="reports.length > 0"
    class="pb-6"
  >
    <base-card
      v-for="(report, index) in reports"
      :key="index"
      :class="index === 0 ? 'mt-2' : 'mt-4'"
    >
      <v-card-title
        class="py-2 light-blue accent-4 white--text flex-column align-start rounded-lg rounded-bl-0 rounded-br-0"
      >
        <div class="text-h6">
          {{ report.operatorName }}
        </div>
        <div class="text-subtitle-2">
          {{ util.formatDateTime(report.startTime, 'MMM DD, HH:mm') }} ~ {{ util.formatDateTime(report.endTime, 'MMM DD, HH:mm') }}
        </div>
      </v-card-title>
      <v-card-text
        class="light-green lighten-5 pt-2"
      >
        <div class="text-subtitle-1 font-weight-bold">
          {{ report.title || 'Unknown' }}
        </div>
        <div v-html="util.convertTextToHtml(report.description)" />
      </v-card-text>
    </base-card>
  </div>
  <div
    v-else
    class="text-h6 text-center mt-4 pink--text"
  >Inga planerade arbeten</div>
</template>

<script>
  import util from '@/utils'

  export default {
    props: {
      reports: {
        type: Array,
        default: () => ([])
      },
    },

    data () {
      return {
        util: util,
      }
    },
  }
</script>
